<template>
  <div>
    <v-form ref="form">
      <dialog-view
          :open="dataState.isModalOpen"
          :title="(dataState.modalData===null ? '' : typeof dataState.modalData.id=='undefined' ? 'Создать услугу' : 'Изменить услугу #'+dataState.modalData.id)"
          @cancel="dataState.modalClose()"
          big
      >
        <template v-slot:barActions v-if="dataState.modalData!==null">
          <log entity-name="Service" :entity-id="dataState.modalData.id"/>
        </template>
        <template v-slot:content v-if="dataState.modalData!==null">
          <v-row no-gutters>
            <v-col cols="6" class="pr-2">
              <sheet-group class="mb-4">
                <div>Базовые</div>
                <v-row class="mt-2 mb-2">
                  <v-col cols="12">
                    <autocomplete-single name="Номенклатура" class="mb-0" :items.sync="nomens" :search-text.sync="nomensSearch" :select.sync="dataState.modalData.nomen" :loading="apiNomens.loading"/>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea label="Название" rows="1" v-model="dataState.modalData.name" :rules="formFieldRules.required" @keyup="serviceHelpers()" auto-grow outlined dense hide-details="auto"></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col>
                        <autocomplete-single
                            name="Тип"
                            class="mb-0"
                            :items="[{id:'service',name:'Услуга'}, {id:'serviceComposite',name:'Составная услуга'}, {id:'product',name:'Товар'}]"
                            :select="dataState.modalData.type==='service' ? {id:'service',name:'Услуга'} : dataState.modalData.type==='serviceComposite' ? {id:'serviceComposite',name:'Составная услуга'} : {id:'product',name:'Товар'}"
                            @update:select="(v)=>v===null ? null : dataState.modalData.type=v.id"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Сортировка" v-model="dataState.modalData.sort" :rules="formFieldRules.required" type="number" outlined dense hide-details="auto"/>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <com-spec-autocomplete-single :select.sync="dataState.modalData.spec" class="mb-0" />
                  </v-col>
                  <v-col cols="6">
                    <com-branch-autocomplete-single :select.sync="dataState.modalData.comBranch" class="mb-0" />
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox v-model="dataState.modalData.isShowForClient" label="Отображать на сервисах (сайт, моб. приложение)" class="mt-0" dense hide-details="auto"/>
                    <v-checkbox v-model="dataState.modalData.isVisitCreateForClient" label="Возможность самозаписи клиентом" class="mt-0" dense hide-details="auto"/>
                  </v-col>
                </v-row>
              </sheet-group>
              <sheet-group v-if="dataState.modalData.type==='serviceComposite'" class="mb-4">
                <v-row no-gutters>
                  <v-col class="mb-4">
                    Настройки составной услуги
                  </v-col>
                  <v-col cols="auto" class="mt-n1">
                    <v-btn small depressed @click="cServiceselectState.modalOpen()">
                      Добавить услуги
                    </v-btn>
                  </v-col>
                  <v-col
                      cols="12"
                      v-for="(serviceComposite,k) in dataState.modalData.cServices"
                      :key="serviceComposite.id"
                      @click="cServicesOpen(serviceComposite.id)"
                      class="clickable"
                  >
                    <v-row class="rowSmall my-0">
                      <v-col align-self="center" class="text-wrap">
                        <div>{{serviceComposite.name}}</div>
                        <div>{{$tools.price(serviceComposite.priceTotal)}}</div>
                      </v-col>
                      <v-col cols="auto">
                        <chip v-if="serviceComposite.spec===null">Без специальности</chip>
                        <chip v-else><v-avatar :color="serviceComposite.spec.color" size="10" class="mr-2" style="margin-top:-3px"/>{{serviceComposite.spec.name}}</chip>
                      </v-col>
                      <v-col cols="auto">
                        <btn-small-accept name="Удалить" @accept="dataState.modalData.cServices = dataState.modalData.cServices.filter(v=>v.id!==serviceComposite.id)"/>
                      </v-col>
                    </v-row>
                    <v-divider v-if="dataState.modalData.cServices.length-1!==k"/>
                  </v-col>
                </v-row>
              </sheet-group>
              <sheet-group class="mb-4">
                <div>Стоимость / Длительность / Cроки</div>
                <v-row class="mt-2 mb-2">
                  <v-col cols="3" class="pt-0 pb-4">
                    <v-text-field
                        label="Длительность в минутах"
                        v-model="dataState.modalData.duration"
                        :rules="formFieldRules.required"
                        class="mt-2" outlined dense hide-details="auto"/>
                  </v-col>
                  <v-col cols="3" class="pt-0 pb-4">
                    <v-text-field
                        label="Срок исполнения"
                        v-model.number="dataState.modalData.resultDuration"
                        class="mt-2" outlined dense hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="3" class="pt-0 pb-4">
                    <v-text-field
                        label="План (с)"
                        v-model="dataState.modalData.startDay"
                        class="mt-2" outlined dense hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="3" class="pt-0 pb-4">
                    <v-text-field
                        label="План (по)"
                        v-model="dataState.modalData.endDay"
                        class="mt-2" outlined dense hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Цена расходных материалов" v-model="dataState.modalData.priceExpend" type="number" :rules="formFieldRules.required" outlined dense hide-details="auto" :disabled="dataState.modalData.type==='serviceComposite' && dataState.modalData.isCompositePrice"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Цена выполнения" v-model="dataState.modalData.priceExec" type="number" :rules="formFieldRules.required" outlined dense hide-details="auto" :disabled="dataState.modalData.type==='serviceComposite' && dataState.modalData.isCompositePrice"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Маржа" outlined dense hide-details="auto" readonly :value="serviceUpdateProfit()" type="number" hint="Рассчитывается автоматически" :disabled="dataState.modalData.type==='serviceComposite' && dataState.modalData.isCompositePrice"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Цена итоговая" v-model="dataState.modalData.priceTotal" type="number" :rules="formFieldRules.required" @keyup="serviceHelpers()" outlined dense hide-details="auto"  :disabled="dataState.modalData.type==='serviceComposite' && dataState.modalData.isCompositePrice"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox v-if="dataState.modalData.type==='serviceComposite'" v-model="dataState.modalData.isCompositePrice" label="Автоматическая сумма из составных частей" class="mt-0" dense hide-details="auto"/>
                    <v-checkbox v-model="dataState.modalData.isPriceLock" label="Скидка запрещена" class="mt-0" dense hide-details="auto"/>
                  </v-col>
                </v-row>
              </sheet-group>
              <service-lab-items ref="labItems" v-if="!$tools.isEmpty(dataState.modalData.id)" :optionsJson.sync="dataState.modalData.optionsJson" :serviceId="dataState.modalData.id"/>
              <sheet-group class="mb-4">
                <div>Описание</div>
                <v-row class="mt-2 mb-4">
                  <v-col cols="12">
                    <v-textarea label="Комментарий для сотрудников" rows="1" v-model="dataState.modalData.comment" auto-grow outlined dense hide-details="auto"/>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea label="Комментарий для клиента при визите" rows="1" v-model="dataState.modalData.commentForClient" auto-grow outlined dense hide-details="auto"/>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea label="Описание на внешних сервисах" rows="1" v-model="dataState.modalData.des" auto-grow outlined dense hide-details="auto"/>
                  </v-col>
                </v-row>
              </sheet-group>
            </v-col>
            <v-col cols="6" class="pl-2">
              <sheet-group class="mb-4">
                <div>Дополнительные настройки</div>
                <v-expansion-panels accordion multiple class="mt-1">
                  <v-expansion-panel v-if="typeof dataState.modalData.id!=='undefined'">
                    <v-expansion-panel-header class="px-2">
                      <div>
                        ДМС / ОМС / Контракт
                        <v-progress-circular v-if="serviceInsurState.isLoading" color="primary" indeterminate class="ml-2 d-inline-block" size="14" width="2"/>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row v-for="(serviceInsur, i) in serviceInsurs" :key="i" class="mx-n7">
                        <v-col cols="12" class="pb-0">
                          {{serviceInsur.insur.name}}
                        </v-col>
                        <v-col v-if="typeof serviceInsur.oms!=='undefined'" cols="3">
                          <v-text-field v-model="serviceInsur.oms" label="ОМС" outlined dense hide-details="auto" type="number"/>
                        </v-col>
                        <v-col v-if="typeof serviceInsur.dms!=='undefined'" cols="3">
                          <v-text-field v-model="serviceInsur.dms" label="ДМС" outlined dense hide-details="auto" type="number"/>
                        </v-col>
                        <v-col v-if="typeof serviceInsur.contract!=='undefined'" cols="3">
                          <v-text-field v-model="serviceInsur.contract" label="Контракт" outlined dense hide-details="auto" type="number"/>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field v-model="serviceInsur.code" label="Код" outlined dense hide-details="auto"/>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="typeof dataState.modalData.id!=='undefined'">
                    <v-expansion-panel-header class="px-2">
                      <div>
                        Специалисты оказывающие услугу
                        <v-progress-circular v-if="userServicesState.isLoading" color="primary" indeterminate class="ml-2 d-inline-block" size="14" width="2"/>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mx-n4">
                      <user-autocomplete-single name="Специалисты" _type="spec" :select.sync="userServicesUser"/>
                      <v-row class="mt-n2 mb-2">
                        <v-col cols="6">
                          <v-text-field label="Длительность в минутах" v-model="userServicesDuration" outlined dense hide-details="auto"/>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Цена итоговая" v-model="userServicesPriceTotal" type="number" outlined dense hide-details="auto"/>
                        </v-col>
                      </v-row>
                      <v-btn @click="userServicesUpdate()" small class="mb-2" depressed>Добавить</v-btn>
                      <alert-view v-if="userServices.length===0" icon="mdi-information-outline" text="Не найдено" />
                      <v-list v-else>
                        <v-list-item v-for="(userService, i) in userServices" :key="i">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters>
                                <v-col>{{userService.user.fullName}}</v-col>
                                <v-col cols="auto" class="mr-2">Длит.: {{userService.duration}} мин.</v-col>
                                <v-col cols="auto">Цена итоговая: {{$tools.price(userService.priceTotal)}}</v-col>
                              </v-row>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <div v-for="userSpec in userService.user.userSpecs" :key="userService.id+'_'+userSpec.id" class="text-wrap">
                                {{userSpec.spec.name}}
                                <v-icon small>mdi-chevron-left</v-icon>
                                {{userSpec.comDiv.comBranch.name}}
                                <v-icon v-if="!$tools.isEmpty(userSpec.commentAdmin)" class="ml-2 mr-1" small>mdi-comment-text-outline</v-icon>
                                {{userSpec.commentAdmin}}
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn @click="userServicesDelete(userService)" icon color="grey" class="mt-n2">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </sheet-group>
              <sheet-group class="mb-4">
                <div>Аналитика</div>
                <v-row class="mt-2 mb-0">
                  <v-col cols="12">
                    <v-text-field label="Внешний код" v-model="dataState.modalData.isid" type="text" outlined dense hide-details="auto"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <!-- <autocomplete-multiple name="Направления" class="mb-0" :items.sync="directions" :select.sync="dataState.modalData.directions" :loading="apiDirections.loading"/> -->

                    <direction-autocomplete-multiple :select.sync="dataState.modalData.directions" categoryValidation/>
                  </v-col>
                  <v-col cols="12">
                    <service-code-search :code="dataState.modalData.code" @success="searchSuccess"/>
                  </v-col>
                  <v-col cols="12">
                    <v-expansion-panels accordion multiple>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="px-2">
                          <div>
                            Цены категорий
                            <v-progress-circular v-if="serviceHelpersState.isLoading" color="primary" indeterminate class="ml-2 d-inline-block" size="14" width="2"/>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div v-if="serviceServiceCats.length>0" class="ml-n4">
                            <div v-for="(serviceServiceCat,index) in serviceServiceCats" :key="'serviceServiceCat_'+serviceServiceCat.id">
                              <div class="caption mt-2">
                                #{{serviceServiceCat.id}}
                                <span class="black--text ml-1">{{serviceServiceCat.name}}</span>
                                <v-chip v-if="serviceServiceCat.isProgram===true" small color="green" dark class="ml-1">Программа</v-chip>
                              </div>
                              <div class="mb-2">
                                Цена:<span class="black--text ml-1">{{$tools.price(serviceServiceCat.price)}}</span>
                              </div>
                              <v-divider v-if="index<serviceServiceCats.length-1"/>
                            </div>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="px-2">
                          <div>
                            Похожие услуги по коду
                            <span v-if="serviceHelpersState.isSuccess">({{serviceSimilars.byCode.length}})</span>
                            <v-progress-circular v-if="serviceHelpersState.isLoading" color="primary" indeterminate class="ml-2 d-inline-block" size="14" width="2"/>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div v-if="typeof serviceSimilars.byCode!=='undefined'" class="ml-n4">
                            <div v-for="(serviceSimilar,index) in serviceSimilars.byCode" :key="'serviceSimilar1_'+serviceSimilar.id">
                              <div class="caption mt-2">
                                #{{serviceSimilar.id}}<span class="black--text ml-1">{{serviceSimilar.name}}</span>
                              </div>
                              <div>
                                Код:
                                <span v-if="serviceSimilar.code!=null" class="black--text ml-1">{{serviceSimilar.code}}</span>
                                <span v-else class="ml-1">Не задан</span>
                              </div>
                              <div class="mb-2">
                                Цена:<span class="black--text ml-1">{{$tools.price(serviceSimilar.priceTotal)}}</span>
                              </div>
                              <v-divider v-if="index<serviceSimilars.byCode.length-1"/>
                            </div>
                            <div v-if="serviceSimilars.byCode.length===0 && serviceHelpersState.isSuccess" class="text-center caption">
                              Не найдено
                            </div>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="px-2">
                          <div>
                            Похожие услуги по названию
                            <span v-if="serviceHelpersState.isSuccess">({{serviceSimilars.byName.length}})</span>
                            <v-progress-circular v-if="serviceHelpersState.isLoading" color="primary" indeterminate class="ml-2 d-inline-block" size="14" width="2"/>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div v-if="typeof serviceSimilars.byCode!=='undefined'" class="ml-n4">
                            <div v-for="(serviceSimilar,index) in serviceSimilars.byName" :key="'serviceSimilar2_'+serviceSimilar.id">
                              <div class="caption mt-2">
                                #{{serviceSimilar.id}}<span class="black--text ml-1">{{serviceSimilar.name}}</span>
                              </div>
                              <div>
                                Код:
                                <span v-if="serviceSimilar.code!=null" class="black--text ml-1">{{serviceSimilar.code}}</span>
                                <span v-else class="ml-1">Не задан</span>
                              </div>
                              <div class="mb-2">
                                Цена:<span class="black--text ml-1">{{$tools.price(serviceSimilar.priceExec)}}</span>
                              </div>
                              <v-divider v-if="index<serviceSimilars.byName.length-1"/>
                            </div>
                            <div v-if="serviceSimilars.byName.length===0 && serviceHelpersState.isSuccess" class="text-center caption">
                              Не найдено
                            </div>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </sheet-group>
            </v-col>
          </v-row>
        </template>
        <template v-slot:actions>
          <dialog-view
              v-if="dataState.modalData!=null && typeof dataState.modalData.id!=='undefined' && typeof disableDelete==='undefined'"
              :title="'Удалить услугу #'+dataState.modalData.id+'?'"
              acceptName="Удалить"
              @acceptAction="serviceDelete()"
              :loading="serviceDeleteState.isLoading"
          >
            <template v-slot:activator>
              <v-btn text color="error">Удалить</v-btn>
            </template>
          </dialog-view>
          <v-btn v-if="dataState.modalData!=null" text color="primary" @click="submit" :loading="dataState.isLoading">
            {{typeof dataState.modalData.id=='undefined' ? 'Создать' : 'Изменить'}}
          </v-btn>
        </template>
      </dialog-view>
    </v-form>
    <visit-service-add
        :open.sync="cServiceselectState.isModalOpen"
        @complete="(arr)=>{
          arr.forEach(v=>dataState.modalData.cServices.filter(v1=>v1.id===v.id || this.state.modalData.id===v.id).length===0 ? dataState.modalData.cServices.push(v) : null);
          dataState.modalData.cServices = dataState.modalData.cServices.sort((a,b)=>{
            if(a.id===b.id) return 0;
            return a.id>b.id ? 1 : -1;
          })
        }"
    />
    <service1 v-if="cServiceState!==null" :state="cServiceState"  @createdUpdatedDeleted="reload()"/>
  </div>
</template>

<script>
import {FormFieldRules} from "@/plugins/formFieldRules";
import DialogView from "@/components/DialogView";
import State from "@/plugins/state";
import axios from "axios";
import Api from "@/Api";
import ServiceCodeSearch from "@/views/Service/ServiceCodeSearch";
import Log from "@/views/Log/Log";
import AlertView from "@/components/AlertView";
import DirectionAutocompleteMultiple from "@/componentsV2/custom/DirectionAutocompleteMultiple.vue";
import GraphqlApi from "@/plugins/graphqlApi";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import ComBranchAutocompleteSingle from "@/componentsV2/custom/ComBranchAutocompleteSingle.vue";
import UserAutocompleteSingle from "@/componentsV2/custom/UserAutocompleteSingle.vue";
import SheetGroup from "@/componentsV2/base/SheetGroup.vue";
import ComSpecAutocompleteSingle from "@/componentsV2/custom/SpecAutocompleteSingle.vue";
import VisitServiceAdd from "@/views/Visit/VisitServiceAdd.vue";
import Chip from "@/componentsV2/base/Chip.vue";
import BtnSmallAccept from "@/componentsV2/base/BtnSmallAccept.vue";
import ServiceLabItems from "@/views/Service/ServiceLabItems.vue";

export default {
  components: {
    ServiceLabItems,
    Service1: () => import('@/views/Service/Service'),
    BtnSmallAccept,
    Chip,
    VisitServiceAdd,
    ComSpecAutocompleteSingle,
    SheetGroup,
    ComBranchAutocompleteSingle,
    AutocompleteSingle, DirectionAutocompleteMultiple, AlertView, Log, ServiceCodeSearch, DialogView, UserAutocompleteSingle},
  props:[
    'state', 'disableDelete'
  ],
  data: () => ({
    formFieldRules: FormFieldRules,

    dataState: new State(),
    serviceDeleteState: new State(),

    cServiceselectState: new State(),
    cServiceState: null,

    serviceHelpersState: new State(),
    serviceServiceCats:[],
    serviceSimilars: [],

    serviceInsurState: new State(),
    serviceInsurs:[],

    userServicesState: new State(),
    userServices:[],
    userServicesUser:null,
    userServicesDuration:null,
    userServicesPriceTotal:null,
    nomens:[],
    apiNomens: new GraphqlApi(),
    nomensSearch: null,
  }),
  watch:{
    state: {
      deep: true,
      handler() {
        if(this.state.isModalOpen){
          if(typeof this.state.modalData.id==='undefined'){
            this.dataState.modalOpen({
              serviceCatId:this.state.modalData.serviceCatId,
              type:'service',
              spec:null,
              cServices:[],
              name:null,
              comment:null,
              commentForClient:null,
              sort:0,
              des:null,
              duration:0,
              resultDuration:0,
              priceExpend:0,
              priceExec:0,
              priceTotal:0,
              code:null,
              isid:null,
              isPriceLock:false,
              isShowForClient:false,
              isVisitCreateForClient:false,
              isCompositePrice:false,
              nomen:null,
              directions:[],

              startDay:0,
              endDay:0,
              comBranch:null,
              optionsJson:null,
            });
          } else {
            this.dataState.modalOpen({
              id:this.state.modalData.id,
              serviceCatId:this.state.modalData.serviceCatId,
              type:this.state.modalData.type,
              cServices:this.state.modalData.cServices,
              spec:this.state.modalData.spec,
              name:this.state.modalData.name,
              comment:this.state.modalData.comment,
              commentForClient:this.state.modalData.commentForClient,
              sort:this.state.modalData.sort,
              des:this.state.modalData.des,
              duration:this.state.modalData.duration,
              resultDuration:this.state.modalData.resultDuration,
              priceExpend:this.state.modalData.priceExpend,
              priceExec:this.state.modalData.priceExec,
              priceTotal:this.state.modalData.priceTotal,
              code:this.state.modalData.code,
              isid:this.state.modalData.isid,
              isPriceLock:this.state.modalData.isPriceLock,
              isShowForClient:this.state.modalData.isShowForClient,
              isVisitCreateForClient:this.state.modalData.isVisitCreateForClient,
              isCompositePrice:this.state.modalData.isCompositePrice,
              nomen:this.state.modalData.nomen,
              directions:this.state.modalData.directions,

              startDay:this.state.modalData.startDay,
              endDay:this.state.modalData.endDay,
              comBranch:this.state.modalData.comBranch,
              optionsJson:this.state.modalData.optionsJson,
            });
          }

          this.serviceHelpers();
          this.serviceInsursLoad();
          this.userServicesLoad();
          if(this.dataState.modalData.nomen!==null)
            this.nomens.push(this.dataState.modalData.nomen);
        }
      },
    },
    dataState: {
      deep: true,
      handler() {
        this.state.isModalOpen = this.dataState.isModalOpen;
      }
    },
    nomensSearch(){
      this.nomensSearchLoad();
    }
  },
  methods:{
    serviceHelpers(){
      this.$tools.throttle(()=> {
        this.serviceServiceCats = [];
        this.serviceSimilars = [];
        this.serviceHelpersState.stateLoading();
        if(typeof this.dataState.modalData.id==='undefined'){
          Api.service.service.serviceHelpersForCreate(
              this.dataState.modalData.serviceCatId,
              this.dataState.modalData.name,
              this.dataState.modalData.code,
              (this.dataState.modalData.priceTotal==='' || this.dataState.modalData.priceExpend===null ?
                  0 : parseFloat(this.dataState.modalData.priceTotal)),
              (d) => {
                this.serviceHelpersState.stateSuccess();
                this.serviceServiceCats = d.serviceCats;
                this.serviceSimilars = d.serviceSimilars;
              }, () => {
                this.serviceHelpersState.stateError();
              });
        } else {
          Api.service.service.serviceHelpersForUpdate(
              this.dataState.modalData.id,
              this.dataState.modalData.name,
              this.dataState.modalData.code,
              (this.dataState.modalData.priceTotal==='' || this.dataState.modalData.priceExpend===null ?
                  0 : parseFloat(this.dataState.modalData.priceTotal)),
              (d)=>{
                this.serviceHelpersState.stateSuccess();
                this.serviceServiceCats = d.serviceCats;
                this.serviceSimilars = d.serviceSimilars;
              }, ()=>{
                this.serviceHelpersState.stateError();
              });
        }
      }, 1000, 'serviceHelpers');
    },

    serviceInsursLoad(){
      this.$tools.throttle(()=> {
        if(typeof this.dataState.modalData.id!=='undefined') {
          this.serviceInsurs = [];
          this.serviceInsurState.stateLoading();
          axios.get(Api.host + '/service/' + this.dataState.modalData.id + '/insur/')
              .then(r => {
                this.serviceInsurs = r.data;
                this.serviceInsurState.stateSuccess();
              })
              .catch(e => {
                this.serviceInsurState.stateError(e);
              });
        }
      }, 1000, 'serviceInsursLoad');
    },

    userServicesLoad(){
      this.$tools.throttle(()=> {
        if(typeof this.dataState.modalData.id!=='undefined') {
          this.userServices = [];
          this.userServicesState.stateLoading();
          axios.get(Api.host + '/service/' + this.dataState.modalData.id + '/user/')
              .then(r => {
                this.userServices = r.data;
                this.userServicesState.stateSuccess();
              })
              .catch(e => {
                this.userServicesState.stateError(e);
              });
        }
      }, 1000, 'userServicesLoad');
    },
    userServicesDelete(v){
      this.userServices = this.userServices.filter((v1)=>v1.id!==v.id);
      this.userServicesUpdate();
    },
    userServicesUpdate(){
      let arr = [];
      arr.push({
        userId:this.userServicesUser.id,
        duration:this.userServicesDuration,
        priceTotal:this.userServicesPriceTotal,
      });
  
      this.userServicesUser = null;
      this.userServicesDuration = null;
      this.userServicesPriceTotal = null;
      this.userServicesState.stateLoading();
      axios.put(Api.host+'/service/'+this.dataState.modalData.id+'/user/', arr)
          .then((r) =>{
            this.userServices = r.data;
            this.userServicesState.stateSuccess();
            this.$emit('createdUpdatedDeleted')
          })
          .catch(e =>{
            Api.responseError(e)
            this.userServicesState.stateError();
          });
    },

    searchSuccess(v){
      this.dataState.modalData.code = v;
      this.serviceHelpers();
    },

    serviceUpdateProfit(){
      let priceExpend = parseFloat(this.dataState.modalData.priceExpend);
      let priceExec = parseFloat(this.dataState.modalData.priceExec);
      let priceTotal = parseFloat(this.dataState.modalData.priceTotal);
      if (!isNaN(priceExpend) && !isNaN(priceExec) && !isNaN(priceTotal)){
        return (priceTotal-(priceExpend+priceExec)).toFixed(2);
      }
      return 0;
    },

    serviceDelete(){
      this.serviceDeleteState.stateLoading();

      axios.delete(Api.host+'/service/'+this.dataState.modalData.id+'/')
          .then(() =>{
            this.$store.commit('alertSuccess', 'Услуга удалена');
            this.serviceDeleteState.stateSuccess();
            this.serviceDeleteState.modalClose();
            this.dataState.modalClose();
            this.$emit('createdUpdatedDeleted')
          })
          .catch(e =>{
            Api.responseError(e)
            this.dataState.stateError();
          });
    },

    submit(){
      if (this.$refs.form.validate()){
        this.$refs.form.resetValidation();
        this.dataState.stateLoading();

        if(typeof this.dataState.modalData.id==='undefined'){
          axios.post(Api.host+'/service/', this.dataState.modalData)
              .then(r =>{
                this.$store.commit('alertSuccess', 'Услуга создана');
                this.dataState.stateSuccess();
                this.dataState.modalClose();
                this.$emit('createdUpdatedDeleted', r.data.id)
              })
              .catch(e =>{
                Api.responseError(e)
                this.dataState.stateError();
                this.$store.commit('alertError', e.response.data.message);
              });
        } else {
          axios.put(Api.host+'/service/'+this.dataState.modalData.id+'/', this.dataState.modalData)
              .then(r =>{
                this.$store.commit('alertSuccess', 'Услуга изменена');
                this.dataState.stateSuccess();
                this.dataState.modalClose();
                this.$emit('createdUpdatedDeleted', r.data.id);

                axios.put(Api.host + '/service/' + this.dataState.modalData.id + '/insur/', this.serviceInsurs);
              })
              .catch(e =>{
                Api.responseError(e)
                this.dataState.stateError();
                this.$store.commit('alertError', e.response.data.message);
              });
        }
      }
    },

    nomensSearchLoad(){
      this.apiNomens.request(
          "/api/graphqlv2/",
          `
            query Query($whereQLParams:String) {
              nomens(
                page: 1
                onPage: 10
                whereQL: "nomen.name LIKE ?1"
                whereQLParams: $whereQLParams
              ) {
                count
                items {
                  id
                  name
                  code
                }
              }
            }
          `,
          {whereQLParams: '%'+this.nomensSearch+'%'},
          (d)=>{
            d.nomens.items.forEach(v1=>{
              if(this.nomens.filter(v=>v.id===v1.id).length===0)
                this.nomens.push(v1);
            })
          },
          (e)=>{
            console.log(e);
            this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
          }
      );
    },
    
    reload(){
       axios.get(Api.host+'/service/'+this.dataState.modalData.id+'/')
         .then(r =>{
           setTimeout(()=>{
             this.state.modalOpen(r.data);
           }, 100);
         })
         .catch(e =>{
           Api.responseError(e)
           this.serviceCatRootsState.stateError();
         });
    },

    cServicesOpen(id){
       axios.get(Api.host+'/service/'+id+'/')
         .then(r =>{
           this.cServiceState = new State();
           setTimeout(()=>{
             this.cServiceState.modalOpen(r.data);
           }, 100);
         })
         .catch(e =>{
           Api.responseError(e)
           this.serviceCatRootsState.stateError();
         });
    },
  }
}
</script>