<template>
  <card-view card-class="ma-6">
    <template v-slot:content>
      <progress-page v-if="userApi.loading || comBranchApi.loading" />
      <div v-else>
        <v-row>
          <v-col cols="12">
            <v-btn
                @click="userSpecSelect={
                  user:{
                    id:$router.currentRoute.params.id
                  },
                  commentAdmin:null,
                  isShowInService:false,
                  isSelfVisitCreate:false,
                  isExternalSelfVisitCreate:false,
                  comUserCat:null,
                  comDiv:null,
                  spec:null,
                  _options:[]
                }"
                small depressed
            >
              Создать
            </v-btn>
          </v-col>
          <v-col v-for="comBranch in comBranchs" :key="comBranch.id" cols="auto">
            <v-sheet min-width="400">
              <h3 class="font-weight-medium mb-2">{{comBranch.name}}</h3>
              <v-list>
                <v-list-item
                  v-for="userSpec in user.userSpecs.filter(v=>v.comDiv.comBranch.id===comBranch.id)" :key="userSpec.id"
                  @click="userSpecSelect=userSpec"
                >
                  <v-row class="my-1">
                    <v-col>
                      <div class="caption">Категория пользователя</div>
                      {{$tools.isEmpty(userSpec.comUserCat) ? 'Не указано' : userSpec.comUserCat.name}}
                    </v-col>
                    <v-col>
                      <div class="caption">Отделение</div>
                      {{userSpec.comDiv.name}}
                    </v-col>
                    <v-col>
                      <div class="caption">Специальности</div>
                      {{userSpec.spec.name}}
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <div v-if="!$tools.isEmpty(userSpec.commentAdmin)" class="mb-2">
                        <v-icon small>mdi-comment-text-outline</v-icon>
                        {{userSpec.commentAdmin}}
                      </div>
                      <group>
                        <div v-if="userSpec.isShowInService">Отображать на сайте и моб.приложении</div>
                        <div v-if="userSpec.isSelfVisitCreate">Самозапись на сайте и моб.приложении</div>
                        <div v-if="userSpec.isExternalSelfVisitCreate">Самозапись на cторонних сервисах</div>
                        <div v-if="!userSpec.isSelfVisitCreate && !userSpec.isExternalSelfVisitCreate">Без самозаписи</div>
                      </group>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>
        </v-row>
      </div>

      <dialog-editor
          :name="userSpecSelect==null || userSpecSelect.id===null ? 'Создать cпециализацию' : 'Редактировать cпециализацию'"
          :item.sync="userSpecSelect"
          @submit="submit"
      >
        <template v-slot:default="{localItem}">
          <com-user-cat-autocomplete :select.sync="localItem.comUserCat"/>
          <com-div-autocomplete :select.sync="localItem.comDiv"/>
          <com-spec-autocomplete :select.sync="localItem.spec"/>
          <text-area-field name="Комментарий администратора" :value.sync="localItem.commentAdmin"/>
          <checkboxes :items="[{id:'isShowInService',name:'Отображать на сайте и моб.приложении'},{id:'isSelfVisitCreate',name:'Самозапись на сайте и моб.приложении'},{id:'isExternalSelfVisitCreate',name:'Самозапись на cторонних сервисах'}]" :selects.sync="localItem._options"/>
        </template>
        <template v-slot:footerActions="{localItem}">
          <btn-text-accept name="Удалить" text="Удалить специализацию?" color="error" @accept="deleteAction(localItem)"/>
        </template>
      </dialog-editor>
    </template>
  </card-view>
</template>

<script>
import Api from "@/Api";
import CardView from "@/components/CardView";
import GraphqlApi from "@/plugins/graphqlApi";
import ProgressPage from "@/components/ProgressPage.vue";
import DialogEditor from "@/componentsV2/base/DialogEditor.vue";
import ComDivAutocomplete from "@/componentsV2/custom/ComDivAutocompleteSingle.vue";
import ComSpecAutocomplete from "@/componentsV2/custom/SpecAutocompleteSingle.vue";
import TextAreaField from "@/componentsV2/base/TextAreaField.vue";
import Checkboxes from "@/componentsV2/base/Checkboxes.vue";
import Group from "@/componentsV2/base/Group.vue";
import ComUserCatAutocomplete from "@/componentsV2/custom/ComUserCatAutocompleteSingle.vue";
import BtnTextAccept from "@/componentsV2/base/BtnTextAccept.vue";

export default {
  components: {
    BtnTextAccept,
    ComUserCatAutocomplete,
    Group,
    Checkboxes, TextAreaField, ComSpecAutocomplete, ComDivAutocomplete, DialogEditor, ProgressPage, CardView},
  data: () => ({
    userApi: new GraphqlApi(), user:{},
    userSpecSelect:null,

    comBranchApi: new GraphqlApi(), comBranchs:[],
  }),
  mounted() {
    this.load();
  },
  methods: {
    load(){
      this.userApi.requestSingleModelSingleDataV1(
        this.user,
        'User',
        '/api/graphql/',
        `
          query Query($id: Int){
            User(id:$id){
              id
              userSpecs{
                id
                commentAdmin
                isShowInService
                isSelfVisitCreate
                isExternalSelfVisitCreate
                comUserCat{
                  id
                  name
                }
                comDiv{
                  id
                  name
                  comBranch{
                    id
                    name
                  }
                }
                spec{
                  id
                  name
                }
              }
            }
          }
        `,
        {id:parseInt(this.$router.currentRoute.params.id)},
        null,
        (v)=>{
          v.userSpecs.forEach(v1=>{
            v1._options = [];
            if(v1.isShowInService) v1._options.push({id:"isShowInService","name":"Самозапись на сайте и моб.приложении"});
            if(v1.isSelfVisitCreate) v1._options.push({id:"isSelfVisitCreate","name":"Самозапись на сайте и моб.приложении"});
            if(v1.isExternalSelfVisitCreate) v1._options.push({id:"isExternalSelfVisitCreate","name":"Самозапись на cторонних сервисах"});
            if(v1.spec!==null)
              v1.spec.id = parseInt(v1.spec.id);
          });
          return v;
        }
      );
      this.comBranchApi.requestSingleModelMultipleDataV1(this.comBranchs, 'ComBranch', '/api/graphql/', `
      query Query{
        ComBranch{
          id
          name
          comDivs{
            id
            name
          }
        }
      }
    `);
    },
    submit(localItem){
      if(localItem.comUserCat===null)
        return this.$store.commit('alertError', 'Категория пользователя должна быть выбрана');
      if(localItem.comDiv===null)
        return this.$store.commit('alertError', 'Отделение должно быть выбрано');
      if(localItem.spec===null)
        return this.$store.commit('alertError', 'Специальность должна быть выбрана');

      localItem.isShowInService = localItem._options.filter(v=>v.id==='isShowInService').length===1;
      localItem.isSelfVisitCreate = localItem._options.filter(v=>v.id==='isSelfVisitCreate').length===1;
      localItem.isExternalSelfVisitCreate = localItem._options.filter(v=>v.id==='isExternalSelfVisitCreate').length===1;

      Api.storage.createUpdate('UserSpec', localItem, ()=>{
        this.userSpecSelect = null;
        this.load();
      },(e)=>{
        this.$store.commit('alertError', e.response.data.message);
      })
    },
    deleteAction(v){
      Api.storage.delete('UserSpec', v.id, ()=>{
        this.userSpecSelect = null;
        this.load();
      },(e)=>{
        this.$store.commit('alertError', e.response.data.message);
      })
    }
  },
};
</script>

