<template>

  <div>
    <div class="d-flex justify-end py-5 px-5"><log :multiEntity="{User:userLocal.id,UserProfile:userLocal.userProfile.id}"/></div>
    <v-expansion-panels accordion v-model="panelOpen">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Основная информация
          <div class="d-inline-block">
            <v-chip v-if="isMainFieldsValidCheck" class="position-absolute ml-2 mt-n3" small color="orange" dark>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="6">
              <v-text-field @keyup="$tools.firstLatterUppercase($event)" v-model="userLocal.lastName" label="Фамилия" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field @keyup="$tools.firstLatterUppercase($event)" v-model="userLocal.firstName" label="Имя" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-text-field @keyup="$tools.firstLatterUppercase($event)" v-model="userLocal.secondName" label="Отчество" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-select v-model="userLocal.userProfile.gender" :items="[{name:'Женщина', value:'female'},{name:'Мужчина', value:'male'}]" item-text="name" item-value="value" label="Пол" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="birth" v-mask="'##.##.####'" :suffix="$tools.dateYears(birth)" label="Дата рождения" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <!-- <v-text-field v-model="userLocal.phone" label="Телефон" outlined dense hide-details="auto" v-mask="'+7 (###) ###-##-##'"/> -->
              <phone-field :phone.sync="userLocal.phone" label="Телефон" required/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.comment" label="Комментарий" auto-grow outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.commentAdd" label="Комментарий доп." auto-grow outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="pregnantDate" v-mask="'##.##.####'" :suffix="$tools.dateYears(pregnantDate)" label="Родила" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="pregnantPayDate" v-mask="'##.##.####'" :suffix="$tools.dateYears(pregnantPayDate)" label="Дата списания" outlined dense hide-details="auto"/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Дополнительные контакты
          <div class="d-inline-block">
            <!-- <v-chip
                v-if="$tools.isEmpty(userLocal.userProfile.phone1) && $tools.isEmpty(userLocal.userProfile.email) && $tools.isEmpty(userLocal.userProfile.whatsapp) && $tools.isEmpty(userLocal.userProfile.telegram) && $tools.isEmpty(userLocal.userProfile.instagram) && $tools.isEmpty(userLocal.userProfile.vk)"
                class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip> -->
                <v-chip
                v-if="$tools.isEmpty(userLocal.userProfile.phone1) && $tools.isEmpty(userLocal.userProfile.email) && $tools.isEmpty(userLocal.userProfile.whatsapp) && $tools.isEmpty(userLocal.userProfile.telegram)"
                class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="12">
              <v-text-field v-model="userLocal.userProfile.phone1" label="Телефон" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="userLocal.userProfile.phoneCompany" label="Рабочий телефон в организации" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="userLocal.userProfile.email" label="Email" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.whatsapp" label="Whatsapp" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.telegram" label="Telegram" outlined dense hide-details="auto"/>
            </v-col>
            <!-- <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.instagram" label="Instagram" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.vk" label="Вконтакте" outlined dense hide-details="auto"/>
            </v-col> -->
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Подпись</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-badge icon="mdi-upload" color="grey" offset-x="28" offset-y="28" bordered>
            <v-btn @click="userSignatureUploadOpen=true" width="200" height="100" color="grey lighten-3" elevation="0">
              <v-icon v-if="userLocal.signatureUrl==null" size="64" color="grey">mdi-draw</v-icon>
              <v-img v-else :src="userLocal.signatureUrl" contain width="200" height="100"/>
            </v-btn>
          </v-badge>
          <uploader
              :dialog.sync="userSignatureUploadOpen"
              :multiple="false"
              :url="'/api/user/'+$router.currentRoute.params.id+'/signature/'"
              @uploadComplete="(v)=>v.length>0 ? userLocal.signatureUrl=v[0] : null"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Адрес проживания
          <div class="d-inline-block">
            <v-chip
                v-if="$tools.isEmpty(userLocal.userProfile.resAddrStreet)"
                class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.resAddrStreet" label="Город, улица, дом" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.resAddrEntrance" label="Подьезд" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.resAddrIntercom" label="Домофон" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.resAddrFloor" label="Этаж" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.resAddrRoom" label="Квартира / Помещение" outlined dense hide-details="auto"/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="userLocal.type==='client'">
        <v-expansion-panel-header>
          Место работы
          <div class="d-inline-block">
            <v-chip
                v-if="$tools.isEmpty(userLocal.userProfile.workOrgName) && $tools.isEmpty(userLocal.userProfile.workOrgPhone)"
                class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.workOrgName" label="Название организации" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.workOrgPhone" label="Телефон организации" outlined dense hide-details="auto"/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Документ, удостоверяющий личность
          <div class="d-inline-block">
            <v-chip
                v-if="$tools.isEmpty(userLocal.userProfile.pasSeries) || $tools.isEmpty(userLocal.userProfile.pasNumber)"
                class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="4">
              <v-text-field v-model="userLocal.userProfile.pasSeries" label="Серия" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="userLocal.userProfile.pasNumber" label="Номер" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.pasBy" label="Кем выдан" rows="1" auto-grow outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="pasDate" v-mask="'##.##.####'" label="Дата выдачи" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12" class="subtitle-2 text--secondary">Адрес регистрации</v-col>
            <v-col cols="9">
              <v-textarea v-model="userLocal.userProfile.pasAddrStreet" label="Город, улица, дом" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="userLocal.userProfile.pasAddrRoom" label="Квартира" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <autocomplete-single name="Тип документа" :items="passTypes" :select.sync="userLocal.userProfile.passType" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          ИНН
          <div class="d-inline-block">
            <v-chip
                v-if="$tools.isEmpty(userLocal.userProfile.inn)"
                class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="12">
              <!-- <v-text-field v-model="userLocal.userProfile.inn" label="Номер" hint="12 цифр" outlined dense hide-details="auto" :rules="userLocal.type='admin'&&userLocal.userProfile.inn.length!=12 ? false : true"/> -->
              <inn-text-field :inn.sync="userLocal.userProfile.inn" required v-if="userLocal.type==='admin'"/>
              <inn-text-field :inn.sync="userLocal.userProfile.inn" v-if="userLocal.type!=='admin'"/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          СНИЛС
          <div class="d-inline-block">
            <v-chip
                v-if="$tools.isEmpty(userLocal.userProfile.snils)"
                class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="12">
              <v-text-field v-model="userLocal.userProfile.snils" label="Номер" outlined dense hide-details="auto"/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Мед. карта
          <div class="d-inline-block">
            <v-chip
                v-if="$tools.isEmpty(userLocal.userProfile.medCardNumber)"
                class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="12">
              <v-text-field v-model="userLocal.userProfile.medCardNumber" :label="'Номер мед. карты'+($tools.isEmpty(userLocal.userProfile.medCardNumber) ? ' (по умолчанию '+user.id+')' : '')" hint="Если не указан, по умолчанию будет использоваться id пользователя"  outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.medCardStore" label="Склад" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.medCardStorePosition" label="Расположение на складе" outlined dense hide-details="auto"/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          ОМС
          <div class="d-inline-block">
            <v-chip v-if="$tools.isEmpty(userLocal.userProfile.insurOms)" class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="6">
              <graphql-view
                query='
                  query Query{
                    Insur(oms:true){
                      id
                      name
                    }
                  }
                '
                :queryVars="{}"
              >
                <template v-slot:content="{data}">
                  <v-select
                      label="Компания"
                      v-model="userLocal.userProfile.insurOms"
                      :items="data.Insur"
                      item-text="name"
                      return-object
                      clearable
                      outlined dense hide-details
                  />
                </template>
              </graphql-view>
            </v-col>
            <v-col cols="6">
              <date-view title="Дата окончания" :date.sync="userLocal.userProfile.insurOmsEnd" dbformat/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.insurOmsDes" label="Описание" rows="2" auto-grow clearable hide-details outlined dense/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          ДМС
          <div class="d-inline-block">
            <v-chip v-if="$tools.isEmpty(userLocal.userProfile.insurDms)" class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="6">
              <graphql-view
                query='
                  query Query{
                    Insur(dms:true){
                      id
                      name
                    }
                  }
                '
                :queryVars="{}"
              >
                <template v-slot:content="{data}">
                  <v-select
                    label="Компания"
                    v-model="userLocal.userProfile.insurDms"
                    :items="data.Insur"
                    item-text="name"
                    return-object
                    clearable
                    outlined dense hide-details
                  />
                </template>
              </graphql-view>
            </v-col>
            <v-col cols="6">
              <date-view title="Дата окончания" :date.sync="userLocal.userProfile.insurDmsEnd" dbformat/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.insurDmsDes" label="Описание" rows="2" auto-grow clearable hide-details outlined dense/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Первичный договор
          <div class="d-inline-block">
            <v-chip v-if="$tools.isEmpty(userLocal.userProfile.contractNumber) && $tools.isEmpty(userLocal.userProfile.contractDate)" class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="6">
              <v-text-field
                  v-model="userLocal.userProfile.contractNumber"
                  :label="'Номер первичного договора'+($tools.isEmpty(userLocal.userProfile.contractNumber) ? ' (по умолчанию '+user.id+')' : '')"
                  hint="Если не указан, по умолчанию будет использоваться id пользователя"
                  outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <date-view title="Дата" :date.sync="userLocal.userProfile.contractDate" dbformat/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Программы лояльности
          <div class="d-inline-block">
            <v-chip
                v-if="$tools.isEmpty(userLocal.userProfile.promoSource)"
                class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="6">
              <v-select v-model="userLocal.userProfile.promoSource" :items="promoSources" item-text="name" item-value="id" label="Рекламный источник" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="6">
              <v-checkbox v-model="userLocal.userProfile.isLeadBonus" label="Начислять бонусы за направления" class="mt-n1" dense hide-details />
            </v-col>
            <v-col cols="6">
            <v-text-field v-model.number="userLocal.userProfile.discount" label="Личная скидка %" outlined dense hide-details="auto" :rules="[discountValidation]"/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="userLocal.type!=='client'">
        <v-expansion-panel-header>
          Медицинские сертификаты
          <div class="d-inline-block">
            <v-chip
                v-if="$tools.isEmpty(userLocal.userProfile.certificateText)"
                class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.certificateText" label="Текст" rows="1" auto-grow outlined dense hide-details="auto"/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="userLocal.type!=='client'">
        <v-expansion-panel-header>
          Описание для внешних сервисов
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="6">
              <v-text-field v-model="userLocal.userProfile.experience" label="Стаж с (год)" outlined dense hide-details="auto" v-mask="'####'"/>
            </v-col>
            <v-col cols="6">
              <v-select v-model="userLocal.userProfile.rank" clearable :items="[{name:'высшая категория', value:'высшая категория'},{name:'кандидат наук', value:'кандидат наук'}]" item-text="name" item-value="value" label="Звание, категория" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.education" label="Образование" rows="5" auto-grow outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.work" label="Опыт работы" rows="5" auto-grow outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.professional" label="Профессиональные интересы" rows="5" auto-grow outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="userLocal.userProfile.description" label="Описание" rows="5" auto-grow outlined dense hide-details="auto"/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Настройки пользователя системы
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="pt-2">
            <v-col cols="12">
              <alert-view icon="mdi-information-outline" text="Только администратор может настраивать пользователя системы" />
            </v-col>
            <v-col cols="12">
              <btn-question
                  title="Сбросить пароль?"
                  @click="resetPassword()"
              >
                <template v-slot:content>
                  <div class="ma-4">Будет сгенерирован новый случайный пароль</div>
                </template>
                Сбросить пароль
              </btn-question>
            </v-col>
            <v-col cols="12">
              <v-select v-model="userLocal.type" :disabled="$store.state.user.type!=='admin'" :items="[{name:'Клиент', value:'client'},{name:'Специалист', value:'spec'},{name:'Администратор', value:'admin'}]" item-text="name" item-value="value" label="Тип" outlined dense hide-details="auto"/>
            </v-col>
            <v-col cols="12">
              <div class="body-2 font-weight-medium">Права на доступ в разделы</div>
              <v-list dense :disabled="$store.state.user.type!=='admin'">
                <v-list-item-group v-model="permsSelected" multiple>
                  <v-list-item v-for="(permsPossible,index) in userLocal.permsPossibles" :key="index">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary"/>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{permsPossible}}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Api from "@/Api";
import GraphqlView from "@/components/GraphqlView";
import DateView from "@/components/DateView";
import InnTextField from "@/components/InnTextField";
import PhoneField from "@/components/PhoneField";
import AlertView from "@/components/AlertView.vue";
import BtnQuestion from "@/components/btnQuestion.vue";
import Uploader from "@/components/Uploader.vue";
import Log from "@/views/Log/Log";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import {Entity_PassType} from "../../../EntityStoreCacheService";

export default {
  components: {
    AutocompleteSingle,
    Uploader, BtnQuestion, AlertView, DateView,  GraphqlView, InnTextField, PhoneField, Log},
  props:['user'],
  data: () => ({
    panelOpen: [],
    promoSources: [],
    userSignatureUploadOpen:null,
    passTypes:[],

    userLocal:{
      id:null,
      firstName:null,
      lastName:null,
      secondName:null,
      phone:null,
      imageUrl:null,
      signatureUrl:null,
      type:null,
      permsPossibles: null,
      perms: null,
      userProfile: {
        id: null,
        gender: null,
        birth: null,
        pregnantDate: null,
        pregnantPayDate: null,
        phone1: null,
        phoneCompany: null,
        email: null,
        comment:null,
        commentAdd:null,
        whatsapp: null,
        telegram: null,
        // instagram: null,
        // vk: null,
        promoSource: null,
        resAddrStreet: null,
        resAddrEntrance: null,
        resAddrIntercom: null,
        resAddrFloor: null,
        resAddrRoom: null,
        pasSeries: null,
        pasNumber: null,
        pasBy: null,
        pasDate: null,
        pasAddrStreet: null,
        pasAddrRoom: null,
        inn: null,
        snils: null,
        medCardNumber: null,
        medCardStore: null,
        insurOms: null,
        insurOmsEnd: null,
        insurOmsDes: null,
        insurDms: null,
        insurDmsEnd: null,
        insurDmsDes: null,
        contractNumber: null,
        contractDate: null,
        isLeadBonus: null,
        certificateText:null,
        discount: null,
        experience:null,
        rank:null,
        education: null,
        work: null,
        professional: null,
        description: null,
        passType:null,
      },
      userSpecs: {
        id: null,
        spec: {
          id: null,
          name: null,
        }
      }
    },
    birthDateActivePicker: false,
  }),
  beforeMount() {
    this.panelOpen = 0;
    this.userLocal = Object.assign(this.userLocal, this.user);
    this.promoSourcesLoad();
  },
  mounted(){
    new Entity_PassType()
        .selects(Entity_PassType.id)
        .selects(Entity_PassType.name)
        .selects(Entity_PassType.code)
        .order(Entity_PassType.sort, true)
        .page(1)
        .onPage(100)
        .addStateCallback((v)=>{
          if(v.success)
            this.passTypes = v.items;
        })
        .request()
  },
  computed: {
    birth: {
      get: function() {
        return moment(this.userLocal.userProfile.birth, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
      set: function(birth) {
        let d = moment(birth, 'DD.MM.YYYY')
        if (birth.length===10 && d.isValid())
          this.userLocal.userProfile.birth = d.format('YYYY-MM-DD');
      }
    },
    pregnantDate: {
      get: function() {
        return this.$tools.isEmpty(this.userLocal.userProfile.pregnantDate) ? '' : moment(this.userLocal.userProfile.pregnantDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
      set: function(v) {
        let d = moment(v, 'DD.MM.YYYY')
        this.userLocal.userProfile.pregnantDate = v.length===10 && d.isValid() ? d.format('YYYY-MM-DD') : null;
      }
    },
    pregnantPayDate: {
      get: function() {
        return this.$tools.isEmpty(this.userLocal.userProfile.pregnantPayDate) ? '' : moment(this.userLocal.userProfile.pregnantPayDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
      set: function(v) {
        let d = moment(v, 'DD.MM.YYYY')
        this.userLocal.userProfile.pregnantPayDate = v.length===10 && d.isValid() ? d.format('YYYY-MM-DD') : null;
      }
    },
    pasDate: {
      get: function() {
        return moment(this.userLocal.userProfile.pasDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
      set: function(pasDate) {
        let d = moment(pasDate, 'DD.MM.YYYY')
        if (pasDate.length===10 && d.isValid())
          this.userLocal.userProfile.pasDate = d.format('YYYY-MM-DD');
      }
    },
    isMainFieldsValidCheck(){
      return this.$tools.isEmpty(this.userLocal.lastName) || this.$tools.isEmpty(this.userLocal.firstName) || this.$tools.isEmpty(this.userLocal.secondName) || this.$tools.isEmpty(this.userLocal.userProfile.gender) || this.$tools.isEmpty(this.birth) || this.$tools.isEmpty(this.userLocal.phone);
    },
    permsSelected:{
      get: function() {
        if(this.userLocal.perms==null)
          return [];
        let arr = [];
        let sels = this.userLocal.perms.split(',');
        Object.keys(this.userLocal.permsPossibles).forEach((k, index)=>{
          if(sels.includes(k))
            arr.push(index);
        });
        return arr;
      },
      set: function(arr) {
        let results = '';
        let permsPossibles = Object.keys(this.userLocal.permsPossibles);
        arr.forEach(v=>{
          results +=permsPossibles[v]+',';
        })
        results = this.$tools.isEmpty(results) ? null : results.substring(0, results.length-1);
        this.userLocal.perms = results;
      }
    }
  },
  watch:{
    userLocal: {
      handler: function(val) {
        this.$emit('update:user', val);
      },
      deep: true
    },
    'userLocal.userProfile.insurOms'(v){
      if(v===null) this.userLocal.userProfile.insurOmsEnd = null;
    },
    'userLocal.userProfile.insurDms'(v){
      if(v===null) this.userLocal.userProfile.insurDmsEnd = null;
    }
  },
  methods:{
    promoSourcesLoad(){
      axios.get(Api.host+'/user/promoSource/')
        .then(r=> {
          this.promoSources = r.data;
        })
        .catch(e=>{
          console.log(e)
        });
    },
    resetPassword(){
      axios.put(Api.host+'/user/'+this.userLocal.id+'/resetPass/')
        .then(r=> {
          this.$store.commit('alertSuccess', 'Новый пароль: '+r.data.pass);
        })
        .catch(e=>{
          this.$store.commit('alertError', e.response.data.message);
        });
    },
    discountValidation(value) {
      const number = Number(value);
      if (isNaN(number)) {
        return "Введите цифры от 0 до 100";
      }
      if (number < 0 || number > 100) {
        return "Введите цифры от 0 до 100";
      }
      return true;
    },
  }
}
</script>