<template>
  <div class="pa-6">
    <uploader
        v-if="mediaDirs.length>0"
        :dialog.sync="isUploadOpen"
        :multiple="true"
        :url="'/api/media/media/upload/?mediaDirId='+mediaDirs[tabIndex].id+'&userId='+$router.currentRoute.params.id"
        @uploadComplete="uploadComplete"
    />
    <v-card elevation="2" class="w-100" :loading="mediaDirsState.isLoading">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="(mediaDir, key) in mediaDirs" :key="key">{{ mediaDir.name }}</v-tab>
        <v-tab-item v-for="(mediaDir, key) in mediaDirs" :key="key">
          <div v-if="mediaDir.des!=null" class="px-4 pt-2 pb-1 body-2">{{ mediaDir.des }}</div>
          <v-btn  class="ml-4 my-2 mr-2" depressed color="primary" @click="isUploadOpen = true">
            <span>Загрузить</span>
            <v-icon class="ml-2" small>mdi-cloud-upload-outline</v-icon>
          </v-btn>

          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="ml-2 my-2" depressed color="primary" :loading="categorysState.isLoading || mediaCreateState.isLoading">
                <span>Создать</span>
                <v-icon class="ml-2" small>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </template>
            <v-sheet max-width="750">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="category in mediaTemplateCategories" :key="'categorys_'+category.id" :class="category._show ? '' : 'd-none'">
                  <v-expansion-panel-header class="font-weight-medium">{{ category.name }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-if="category.subcategories.length===0&&category.mediaTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                    <v-expansion-panels accordion class="expansionPanelContentExpanded">
                      <v-expansion-panel v-for="subcategory in category.subcategories" :key="'categorys1_'+subcategory.id">
                        <v-expansion-panel-header class="font-weight-medium">{{ subcategory.name }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list>
                            <v-list-item v-for="mediaTemplate in subcategory.mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                              <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                            </v-list-item>
                            <div v-if="typeof subcategory.mediaTemplates !=='undefined'&&subcategory.mediaTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                          </v-list>
                        </v-expansion-panel-content>

                      </v-expansion-panel>
                    </v-expansion-panels>
                       <v-list v-if="category.mediaTemplates.length!==0">
                          <v-list-item v-for="mediaTemplate in category.mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                            <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-list v-if="mediaTemplates.length>0">
                <v-list-item v-for="mediaTemplate in mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                  <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-menu>

          <v-btn
              v-if="mediaDir.id===4"
              @click="nvCreateCurrentYear()"
              class="ml-4"
              depressed
              color="primary"
              :loading="nvCreateCurrentYearState.isLoading"
          >
            Налоговый вычет за текущий год
          </v-btn>

          <v-data-table
              :headers="headers"
              :items="medias.data"         
              :loading="mediasState.isLoading"
              :options.sync="options"
              :server-items-length="total"
              :footer-props="{
                'items-per-page-options': [25, 50, 100]
              }"
              :items-per-page="25"
              dense
              :height="height"
          >
            <template v-slot:item="{ item }">
              <tr @click="mediaId = item.id">
                <td class="text-no-wrap">{{item.id}}</td>
                <td class="text-no-wrap">{{item.name}}</td>
                <td class="text-no-wrap">
                  <div v-if="misDesktopOnline">
                    <v-btn small depressed @click.stop="misDesktopOpenFile(item)"><v-icon class="mr-2" small>mdi mdi-open-in-new</v-icon>Открыть локально</v-btn>
                  </div>
                </td>
                <td>
                  <v-tooltip v-if="item.status==='unactive'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small class="mr-2">mdi-file-alert</v-icon>
                    </template>
                    <span>Черновик</span>
                  </v-tooltip>
                </td>
                <td class="text-no-wrap">{{item.userAuthor.name}}</td>
                <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
                <td class="text-no-wrap">
                  <v-chip
                    v-if="item.isSent"
                    class="font-weight-medium"
                    color="green"
                    text-color="white"
                    small
                  >
                    Отправлено
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <media-view :media-id.sync="mediaId" :user-id="$router.currentRoute.params.id" :userName="userName"/>
    
    <dialog-view :open="mediaCreateErrorState.isModalOpen" title="Ошибка создания документа" @cancel="mediaCreateErrorState.modalClose()">
      <template v-slot:content>
        <div v-if="mediaCreateErrorState.isModalOpen" v-html="mediaCreateErrorState.modalData.replace(/([^>])\n/g, '$1<br/>')"></div>
      </template>
    </dialog-view>
  </div>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import MediaView from "@/views/Media/MediaView";
import Uploader from "@/components/Uploader";
import DialogView from "@/components/DialogView";
import axios from "axios";
import moment from "moment/moment";

export default {
  components:{DialogView, Uploader, MediaView},
  props:['userName'],
  data: () => ({
    mediaDirsState: new State(),
    mediaDirs: [],
    tabIndex:0,
    options:{},
    mediasState: new State(),
    medias: [],
    headers: [
      { text: 'ID', value: 'id', class:'text-no-wrap', width:'auto'},
      { text: 'Название', value: 'name', class:'text-no-wrap'},
      { text: '', sortable: false},
      { text: '', sortable: false},
      { text: 'Автор', value: 'userAuthor', class:'text-no-wrap'},
      { text: 'Дата создания', value: 'created', class:'text-no-wrap'},
      { text: 'Отправка на Email', sortable: false},
    ],
    total:0,
    mediaId: null,

    mediaCreateState: new State(),
    mediaCreateErrorState: new State(),

    mediaTemplates: [],

    isUploadOpen: false,

    misDesktopOnline:false,
    height:null,

    categorysState: new State(),
    mediaTemplateCategories: [],

    nvCreateCurrentYearState: new State(),
  }),
  mounted() {
    this.height = window.innerHeight - 240-48-20;
    this.loadMediaDirs();
    this.$tools.misDesktopStatus((v)=>{
      this.misDesktopOnline = v;
    });
    this.loadMediasCategories(2)
  },
  watch:{
    options: {
      handler () {
        if (this.mediasState.isSuccess) {
          this.loadMedias();
        }
      },
      deep: true,
    },
    tabIndex(index){
      this.loadMedias(this.mediaDirs[index].id);
      this.loadMediasCategories(this.mediaDirs[index].id)
    }
  },
  methods:{
    loadMediaDirs(){
      this.mediaDirsState.stateLoading();
      Api.service.media.mediaDirs(1, 25, (v)=>{
        this.mediaDirsState.stateSuccess();
        this.mediaDirs = v;
        this.loadMedias(this.mediaDirs[0].id);
      }, ()=>{
        this.mediaDirsState.stateError();
      });
    },

    loadMedias(){
      this.medias = [];
      this.mediasState.stateLoading();
      Api.service.media.medias(this.mediaDirs[this.tabIndex].id, this.$router.currentRoute.params.id, this.options.page, this.options.itemsPerPage, (v)=>{
        this.total = v.total

        this.medias = v;
        
        this.mediasState.stateSuccess();
      }, ()=>{
        this.mediasState.stateError();
      });
    },

    loadMediasCategories(id){
      this.categorysState.stateLoading();
      Api.service.media.mediaTemplateCategory(id,(v)=>{
        this.mediaTemplates = [];
        let mediaCategories = [];
        v.data[0].mediaTemplateCategorys.forEach((v)=>{
          v._show = false;
          if (v.subcategories.length!==0||v.mediaTemplates.length!==0)
            v._show = true;
            mediaCategories.push(v);
        })
        this.mediaTemplateCategories = mediaCategories;
        if (typeof v.data[0].mediaTemplates!=undefined&&v.data[0].mediaTemplates.length>0)
         this.mediaTemplates = Object.values(v.data[0].mediaTemplates)

        this.categorysState.stateSuccess();
        }, ()=>{
          this.categorysState.stateError();
        });
    },

    mediaCreateAction(mediaTemplateId){
      this.mediaCreateState.stateLoading();
      axios.post(Api.host+'/media/mediaTemplate/'+mediaTemplateId+'/mediaCreate/', {
        userId:this.$router.currentRoute.params.id
      })
          .then(r=>{
            this.mediaCreateState.stateSuccess();
            this.loadMedias();
            this.$tools.misDesktopStatus((v)=>{
              if(v){
                this.$tools.misDesktopOpenFile(
                    r.data.fileName,
                    r.data.name + r.data.fileName.substring(r.data.fileName.lastIndexOf("."), r.data.fileName.length),
                    "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
                    {id: r.data.id},
                    ()=>{
                      this.$store.commit('alertSuccess', 'Файл открывается локально');
                    }, ()=>{
                      this.$store.commit('alertError', 'Не удалось открыть файл локально');
                    }
                );
              } else {
                this.mediaId = r.data.id;
              }
            });
          })
          .catch((e)=>{
            console.log(e);
            this.mediaCreateState.stateError();
          });
    },

    uploadComplete(){
      this.loadMedias();
    },

    misDesktopOpenFile(v){
      axios.get(Api.host + '/media/media/' + v.id + '/').then(r1 => {
        let mediaData = r1.data;
        this.$tools.misDesktopOpenFile(
            mediaData.filename,
            mediaData.name + mediaData.filename.substring(mediaData.filename.lastIndexOf("."), mediaData.length),
            "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
            {
              id: v.id,
            },
            ()=>{
              this.$store.commit('alertSuccess', 'Файл открывается локально');
            }, ()=>{
              this.$store.commit('alertError', 'Не удалось открыть файл локально');
            }
        );
      });
    },

    nvCreateCurrentYear(){
      this.nvCreateCurrentYearState.stateLoading();
      axios.post(Api.host+'/analytic/sreport/itc/', {
        userId: this.$router.currentRoute.params.id,
        year: moment().format('YYYY')
      })
          .then(() =>{
            this.nvCreateCurrentYearState.stateSuccess();
            this.loadMedias();
          })
          .catch(() =>{
            this.nvCreateCurrentYearState.stateError();
          });
    }
  }
}
</script>